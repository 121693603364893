import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import {
    Typography,
    Button,
    Box,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton
} from "@vp/swan";
import { useDesigner } from "@designer-suite";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { updateSelectedItems } from "../../util";
import "./itemDebugPanel.scss";

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: "easel.ui.debugItem.ariaLabelClose",
        defaultMessage: "Close debug item dialog",
        description: {
            note: "Aria label for a close button of debug item modal"
        }
    }
});

export interface ItemDebugPanelProps {
    showModal: boolean;
    selection: CanvasItem[];
    closeModal: () => void;
}

const ItemDebugPanel = ({ showModal, selection, closeModal }: ItemDebugPanelProps) => {
    const designer = useDesigner();
    const [stringifiedItemViewModel, setStringifiedItemViewModel] = useState("");
    const [stringifiedItemViewModelModel, setStringifiedItemViewModelModel] = useState("");
    const [itemUpdated, setItemUpdated] = useState(false);
    const [itemViewModelUpdated, setItemViewModelUpdated] = useState(false);
    const { t } = useTranslationSSR();

    const updateItemViewModel = () => {
        setItemViewModelUpdated(false);
        updateSelectedItems(designer, selection, mutableItem => {
            Object.entries(JSON.parse(stringifiedItemViewModel)).forEach(([key, value]) => {
                mutableItem._itemViewModel.set(key, value);
            });
            setItemViewModelUpdated(true);
        });
    };

    useEffect(() => {
        setStringifiedItemViewModel(JSON.stringify(selection[0]?._itemViewModel, null, 2));
        setStringifiedItemViewModelModel(JSON.stringify(selection[0]?._itemViewModel?.model, null, 2));
    }, [selection]);

    const updateItemModel = () => {
        setItemUpdated(false);
        updateSelectedItems(designer, selection, mutableItem => {
            Object.entries(JSON.parse(stringifiedItemViewModelModel)).forEach(([key, value]) => {
                mutableItem._itemViewModel.model.set(key, value);
            });
            setItemUpdated(true);
        });
    };

    return (
        <LegacyModalDialog isOpen={showModal} data-dcl-prevent-canvas-items-deselection onRequestDismiss={closeModal}>
            <LegacyModalDialogContent className="easel-item-debug-dialog" aria-label="Debug Panel">
                <LegacyModalDialogCloseButton visuallyHiddenLabel={t(messages.closeButtonAriaLabel.id)} />
                <LegacyModalDialogHeader data-dcl-prevent-canvas-items-deselection>Item Debug</LegacyModalDialogHeader>
                <LegacyModalDialogBody data-dcl-prevent-canvas-items-deselection>
                    <Box mb={4}>
                        <Typography fontWeight="bold" component="span">
                            _itemViewModel Attributes
                        </Typography>
                        <AceEditor
                            className="ace-editor"
                            mode="json"
                            theme="tomorrow"
                            name="itemViewModelAttributes"
                            onChange={e => setStringifiedItemViewModel(e)}
                            onLoad={editor => {
                                // eslint-disable-next-line no-param-reassign
                                editor.container.style.resize = "both";
                                document.addEventListener("mouseup", () => editor.resize());
                            }}
                            fontSize={14}
                            value={stringifiedItemViewModel}
                        />
                        <Button onClick={updateItemViewModel} skin="primary" size="mini" width="standard">
                            Update ItemViewModel
                        </Button>
                        {itemViewModelUpdated && (
                            <Typography fontSize="1" textAlign="left" fontWeight="normal">
                                ItemViewModel Updated
                            </Typography>
                        )}
                    </Box>
                    <Box>
                        <Typography fontWeight="bold" component="span">
                            _itemViewModel.model Attributes
                        </Typography>
                        <AceEditor
                            className="ace-editor"
                            mode="json"
                            theme="tomorrow"
                            name="itemViewModelModelAttributes"
                            onChange={e => setStringifiedItemViewModelModel(e)}
                            onLoad={editor => {
                                // eslint-disable-next-line no-param-reassign
                                editor.container.style.resize = "both";
                                document.addEventListener("mouseup", () => editor.resize());
                            }}
                            fontSize={14}
                            value={stringifiedItemViewModelModel}
                        />
                        <Button onClick={updateItemModel} skin="primary" size="mini" width="standard">
                            Update Model
                        </Button>
                        {itemUpdated && (
                            <Typography fontSize="1" textAlign="left" fontWeight="normal">
                                Model Updated
                            </Typography>
                        )}
                    </Box>
                </LegacyModalDialogBody>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
};
ItemDebugPanel.displayName = "ItemDebugPanel";

export { ItemDebugPanel };
